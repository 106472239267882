const sgipOptionsList = [
  "2020 - Equity Resilience",
  "2020 - Large Scale Storage",
  "2020 - Non-Residential Storage Equity",
  "2021 - Equity Resilience",
  "2021 - Large Scale Storage",
  "2021 - Non-Residential Storage Equity",
  "2022 - Equity Resilience",
  "2022 - Large Scale Storage",
  "2022 - Non-Residential Storage Equity",
  "2023 - Equity Resilience",
  "2023 - Large Scale Storage",
  "2023 - Non-Residential Storage Equity",
  "2024 - Equity Resilience",
  "2024 - Large Scale Storage",
  "2024 - Non-Residential Storage Equity",
];

export default sgipOptionsList;
