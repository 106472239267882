/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import { fatherAssemblies, keys } from "../Logic/formatUtils";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";

/**
 * Renders a CustomLegend component displaying checkboxes for each entry in the payload.
 *
 * @param {object} props - The component props.
 * @param {Array} props.payload - The legend data payload.
 * @param {object} props.areaVisibility - The state object containing the visibility of each area.
 * @param {function} props.setAreaVisibility - The function to update the area visibility state.
 * @returns {JSX.Element} - The rendered CustomLegend component.
 */
const CustomLegend = ({
  payload,
  areaVisibility,
  setAreaVisibility,
  updateAnimationState,
  timezone,
}) => {
  const [openEntries, setOpenEntries] = useState([]);
  const fatherSet = new Set();
  const typeCountMap = new Map();

  const sortedPayload = payload.sort((a, b) => {
    // Use type for sorting based on the order in the 'keys' array
    const typeA = a.type || a.value; // Use value if type is null or undefined
    const typeB = b.type || b.value;

    // Compare the index of each type in the 'keys' array
    return keys.indexOf(typeA) - keys.indexOf(typeB);
  });

  payload.forEach((entry) => {
    if (entry.type) {
      // Increment the count for each type
      typeCountMap.set(entry.type, (typeCountMap.get(entry.type) || 0) + 1);
    }
  });

  // Populate the fatherSet based on the conditions
  typeCountMap.forEach((count, type) => {
    if (count > 1) {
      // If more than one entry, add an object with the type and its corresponding color
      const color = payload.find((entry) => entry.type === type)?.color; // Get the color for the type
      fatherSet.add({ value: type, type: type, color: color });
    } else {
      // If only one entry, find and add its value and color to the set
      const singleValueEntry = payload.find((entry) => entry.type === type);
      if (singleValueEntry) {
        fatherSet.add({ value: singleValueEntry.value, type: null, color: singleValueEntry.color });
      }
    }
  });

  const getTypeByValue = (value) => {
    const entry = payload.find((item) => item.value === value);
    return entry ? entry.type : null; // Return the type if found, otherwise return null
  };

  /**
   * Handles the checkbox change event.
   *
   * @param {object} event - The checkbox change event.
   */
  const handleCheckboxChange = (event) => {
    updateAnimationState(true);
    const { name, checked } = event.target;

    let newVisibility = { ...areaVisibility };

    if (!fatherAssemblies.includes(name)) {
      const parentValue = getTypeByValue(name);
      if (checked) {
        newVisibility[parentValue] = false; // Disable the parent if checked
      }
      newVisibility[name] = checked; // Update the checkbox's own visibility
    } else {
      if (checked) {
        const childEntries = getChildEntries(name);
        childEntries.forEach((childEntry) => {
          newVisibility[childEntry] = false;
        });
      }
      newVisibility[name] = checked;
    }

    setAreaVisibility(newVisibility);
  };

  /**
   * Retrieves the child entries for a given parent value.
   *
   * @param {string} parentValue - The parent value to filter the child entries.
   * @returns {Array} - The array of child entries.
   */
  const getChildEntries = (parent) => {
    return sortedPayload
      .filter((entry) => entry.type === parent) // Filter where type matches the parent
      .map((entry) => entry.value); // Return the child values
  };

  /**
   * Toggles the visibility of child entries and updates the openEntries state.
   *
   * @param {string} parent - The parent value.
   * @param {Array} openEntries - The array of open entries.
   */
  const hideShowChilds = (parent, openEntries) => {
    if (!openEntries.includes(parent)) {
      setOpenEntries((prevOpenEntries) => [...prevOpenEntries, parent]);
    } else {
      setOpenEntries((prevOpenEntries) => prevOpenEntries.filter((value) => value !== parent));
    }

    const childDiv = document.getElementsByClassName(`child-entries-${parent}`)[0];
    if (!openEntries.includes(parent)) {
      childDiv.style.visibility = "hidden";
      childDiv.style.height = 0;
    } else {
      childDiv.style.removeProperty("height");
      childDiv.style.removeProperty("visibility");
    }
  };

  // Convert the Set to an array for rendering
  const fatherAssembly = Array.from(fatherSet);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {fatherAssembly.map((entry, index) => {
        const childEntries = getChildEntries(entry.value);
        return (
          <React.Fragment key={`legend-${index}`}>
            <div
              key={`legend-${index}`}
              className="legend-row"
              style={{ marginLeft: childEntries.length > 1 ? "-26px" : "0", marginTop: "2px" }}
            >
              {childEntries.length > 1 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => hideShowChilds(entry.value.toLowerCase(), openEntries)}
                >
                  {!openEntries.includes(entry.value.toLowerCase()) ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              )}
              <label className="legend-checkbox">
                <input
                  type="checkbox"
                  name={entry.value}
                  checked={areaVisibility[entry.value]}
                  onChange={handleCheckboxChange}
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor:
                      entry.value === "Load"
                        ? "#fff"
                        : areaVisibility[entry.value]
                          ? entry.color
                          : "#fff",
                    border: entry.value === "Load" ? "1px dashed #000000" : "1px solid #979797",
                    "--checkmark-border":
                      entry.value === "Load" ? "1px solid #000" : "1px solid #fff",
                  }}
                ></span>
                <span className="legend-text">{entry.value}</span>
              </label>
            </div>
            {fatherAssemblies.includes(entry.value) && childEntries.length > 1 && (
              <div
                className={`child-entries-${entry.value.toLowerCase()}`}
                style={{ marginLeft: "20px" }}
              >
                {getChildEntries(entry.value).map((childEntry, childIndex) => (
                  <div key={`legend-${index}-child-${childIndex}`} className="legend-row">
                    <label className="legend-checkbox">
                      <input
                        type="checkbox"
                        name={childEntry}
                        checked={areaVisibility[childEntry]}
                        value={childEntry}
                        onChange={handleCheckboxChange}
                      />
                      <span
                        className="checkmark"
                        style={{
                          backgroundColor: areaVisibility[childEntry]
                            ? sortedPayload.find((item) => item.value === childEntry)?.color
                            : "#fff",
                          border:
                            entry.value === "Load" ? "1px dashed #000000" : "1px solid #979797",
                          "--checkmark-border":
                            entry.value === "Load" ? "1px solid #000" : "1px solid #fff",
                        }}
                      ></span>
                      <span className="legend-text" style={{ marginLeft: "10px" }}>
                        {childEntry.replace(/_/g, " ")}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        );
      })}

      {areaVisibility["missingData"] !== undefined && (
        <div key={`legend-missingData`} className="legend-row">
          <label className="legend-checkbox">
            <input
              type="checkbox"
              name="missingData"
              checked={areaVisibility["missingData"]}
              value={"missingData"}
              onChange={handleCheckboxChange}
            />
            <span
              className="checkmark"
              style={{
                backgroundColor: areaVisibility["missingData"] ? "#F3A944" : "#fff",
                border: "1px solid #979797",
                "--checkmark-border": "1px solid #fff",
              }}
            ></span>
            <span className="legend-text">Missing Data</span>
          </label>
        </div>
      )}

      <div key={`legend-alarm`} className="legend-row">
        <label className="legend-checkbox">
          <input
            type="checkbox"
            name="alarms"
            checked={areaVisibility["alarms"]}
            value={"alarms"}
            onChange={handleCheckboxChange}
          />
          <span
            className="checkmark"
            style={{
              backgroundColor: areaVisibility["alarms"] ? "red" : "#fff",
              border: "1px solid #979797",
              "--checkmark-border": "1px solid #fff",
            }}
          ></span>
          <span className="legend-text">Alarms</span>
        </label>
      </div>
      {timezone ? <div className="legend-timezone">{timezone}</div> : null}
    </Box>
  );
};

CustomLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
        .isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  areaVisibility: PropTypes.object,
  setAreaVisibility: PropTypes.func,
  updateAnimationState: PropTypes.func,
  flags: PropTypes.object,
  timezone: PropTypes.string,
};

export default CustomLegend;
